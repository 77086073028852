import React from "react";
import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Autoplay, Pagination, Navigation } from "swiper";

function Services() {
  return (
    <section id="services" className="services section-bg ">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>What You Can Get from Next Technolabs?</h2>
          {/* <p>
            Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
            aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
            quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
          </p> */}
        </div>

        <div className="row row-flex">
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              {/* <i className="bi bi-briefcase"></i> */}
              <i class="bi bi-phone"></i>
              <h4>
                <Link href="#">Mobile App Development</Link>
              </h4>
              <p>
                iOS App Development
                <br />
                Android App Development
                <br />
                Flutter App Development
                <br />
                React Native App Development
                <br />
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              {/* <i className="bi bi-card-checklist"></i> */}
              <i class="bi bi-window-stack"></i>
              <h4>
                <Link href="#">Front end Web Development</Link>
              </h4>
              <p>
                AngularJS Development
                <br />
                ReactJS Development
                <br />
                VueJS Development
                <br />
                JavaScript Development
                <br />
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <i class="bi bi-gear-wide-connected"></i>
              <h4>
                <Link href="#">Backend Web Development</Link>
              </h4>
              <p>
                Nodejs Development
                <br />
                Laravel Development
                <br />
                Python Development
                <br />
                ASP.NET Development
                <br />
                Java Development
                <br />
                Codeigniter Development
                <br />
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <i className="bi bi-bar-chart"></i>
              <h4>
                <Link href="#">Content Management (CMS)</Link>
              </h4>
              <p>
                Wordpress
                <br />
                Magento
                <br />
                Shopify <br />
              </p>
            </div>
          </div>

          {/* <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
              <i className="bi bi-brightness-high"></i>
              <h4>
                <Link href="#">Magni Dolore</Link>
              </h4>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
              <i className="bi bi-calendar4-week"></i>
              <h4>
                <Link href="#">Eiusmod Tempor</Link>
              </h4>
              <p>
                Et harum quidem rerum facilis est et expedita distinctio. Nam
                libero tempore, cum soluta nobis est eligendi
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Services;
