import React from "react";
import {
  BlogList,
  BreadCrumb,
  Footer,
  NavBar,
  ScrollToTop,
} from "../components";

function Blog() {
  const pageList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Blog",
    },
  ];
  return (
    <div>
      <NavBar />
      <BreadCrumb pageList={pageList} />
      <BlogList />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Blog;
