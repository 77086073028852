import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const [toggle, setToggle] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState('');
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  useEffect(() => {
    setSelectedDiv(window.location.hash)
  }, []);
  const changeDiv =(id)=>{
    setSelectedDiv(id)
    setToggle(false)
  }
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <Link to="/">
            <img src="/assets/img/logo.png" alt="Next Technolabs" />
          </Link>
        </h1>

        <nav
          id="navbar"
          className={`navbar order-last order-lg-0 ${toggle ? 'navbar-mobile' : ''}`}
        >
          <ul>
            <li onClick={()=>{
              changeDiv('')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '' ? 'active' : ''}`} smooth="true" to="/">
                Home
              </Link>
            </li>
            <li onClick={()=>{
              changeDiv('#about')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '#about' ? 'active' : ''}`}  smooth="true" to="/#about">
                About Us
              </Link>
            </li>
            <li onClick={()=>{
              changeDiv('#tabs2')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '#tabs2' ? 'active' : ''}`} smooth="true" to="/#tabs2">
                Hire Developer
              </Link>
            </li>
            <li onClick={()=>{
              changeDiv(' #services')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '#services' ? 'active' : ''}`} smooth="true" to="/#services">
                Services
              </Link>

            </li>
            <li onClick={()=>{
              changeDiv('#services')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '#industries' ? 'active' : ''}`} smooth="true" to="/#industries">
                Industries
              </Link>
            </li>
            <li onClick={()=>{
              changeDiv('#contact')}}>
              <Link className={`nav-link scrollto  ${selectedDiv === '#contact' ? 'active' : ''}`} smooth="true" to="/#contact">
                Contact
              </Link>
            </li>
          </ul>
          <i
            onClick={() => {
              setToggle(!toggle);
            }}
            className={`bi mobile-nav-toggle bi-list ${toggle && "bi-x"}`}
          ></i>
        </nav>

      </div>
    </header>
  );
}

export default NavBar;
