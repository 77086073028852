import React from "react";
import { Link } from "react-router-dom";

function BlogSidebar() {
  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <h3 className="sidebar-title">Search</h3>
        <div className="sidebar-item search-form">
          <form action="">
            <input type="text" />
            <button type="submit">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>

        <h3 className="sidebar-title">Categories</h3>
        <div className="sidebar-item categories">
          <ul>
            <li>
              <Link href="blog-view">
                General <span>(25)</span>
              </Link>
            </li>
            <li>
              <Link href="blog-view">
                Lifestyle <span>(12)</span>
              </Link>
            </li>
            <li>
              <Link href="blog-view">
                Travel <span>(5)</span>
              </Link>
            </li>
            <li>
              <Link href="blog-view">
                Design <span>(22)</span>
              </Link>
            </li>
            <li>
              <Link href="blog-view">
                Creative <span>(8)</span>
              </Link>
            </li>
            <li>
              <Link href="blog-view">
                Educaion <span>(14)</span>
              </Link>
            </li>
          </ul>
        </div>

        <h3 className="sidebar-title">Recent Posts</h3>
        <div className="sidebar-item recent-posts">
          <div className="post-item clearfix">
            <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
            <h4>
              <Link href="/blog-view">Nihil blanditiis at in nihil autem</Link>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>

          <div className="post-item clearfix">
            <img src="assets/img/blog/blog-recent-2.jpg" alt="" />
            <h4>
              <Link href="/blog-view">Quidem autem et impedit</Link>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>

          <div className="post-item clearfix">
            <img src="assets/img/blog/blog-recent-3.jpg" alt="" />
            <h4>
              <Link href="/blog-view">
                Id quia et et ut maxime similique occaecati ut
              </Link>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>

          <div className="post-item clearfix">
            <img src="assets/img/blog/blog-recent-4.jpg" alt="" />
            <h4>
              <Link href="/blog-view">Laborum corporis quo dara net para</Link>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>

          <div className="post-item clearfix">
            <img src="assets/img/blog/blog-recent-5.jpg" alt="" />
            <h4>
              <Link href="/blog-view">
                Et dolores corrupti quae illo quod dolor
              </Link>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>

        <h3 className="sidebar-title">Tags</h3>
        <div className="sidebar-item tags">
          <ul>
            <li>
              <Link href="blog-view">App</Link>
            </li>
            <li>
              <Link href="blog-view">IT</Link>
            </li>
            <li>
              <Link href="blog-view">Business</Link>
            </li>
            <li>
              <Link href="blog-view">Mac</Link>
            </li>
            <li>
              <Link href="blog-view">Design</Link>
            </li>
            <li>
              <Link href="blog-view">Office</Link>
            </li>
            <li>
              <Link href="blog-view">Creative</Link>
            </li>
            <li>
              <Link href="blog-view">Studio</Link>
            </li>
            <li>
              <Link href="blog-view">Smart</Link>
            </li>
            <li>
              <Link href="blog-view">Tips</Link>
            </li>
            <li>
              <Link href="blog-view">Marketing</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BlogSidebar;
