import React from "react";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Pagination]);
SwiperCore.use([EffectCoverflow, Pagination]);

function Testimonials() {
  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Testimonials</h2>
          <p>Raving Reviews: What Our Customers are Saying</p>
        </div>

        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Swiper
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            loop={true}
            pagination={{
              clickable: true,
            }}
            className="swiper-wrapper"
          >
            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Nativescript Social Application</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    He's a great guy to work with. Very helpful in giving
                    suggestions.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Full Stack Developers required</h3>
                  <h4>
                    Android, iOS, Ipad mobile application development with
                    backend Laravel/Node & AWS
                  </h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Always go above and beyond. Technical abilities are
                    brilliant and I wont use anyone else for further projects.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Android App Upgrades & iOS App creation</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Complete loyalty and hard work. Next Technolabs team did it
                    again full communication and updates with regular progress
                    reports great working with Next Technolabs.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>
                    React Native Android & iOS with in app purchase, laravel or
                    Node.js Backend
                  </h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Team need to implement advertisements and in-app purchases
                    on our Android application. Next Technolabs and his team got
                    straight to work turning the workaround in a very timely
                    fashion. Keeping me up to speed throughout. Highly
                    Recommended
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>React Native Developer</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    NT Team has helped me with a very difficult project where a
                    developer had let me down. NT and his team took on the
                    challenge and despite the difficulty turned it around and
                    completed the works successfully and to a very high
                    professional standard. Exceptional work from NT and his
                    team, very grateful thank you
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>
                    React Native Android & iOS with in-app purchase, Laravel or
                    Node.js Backend
                  </h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    NT is absolutely brilliant a true professional in his craft.
                    I will always use NT moving forward as my lead developer on
                    various projects. Great working with him
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Mobile App Developer (Android + IOS)</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Excellent Team to work with. Always available for
                    communication, responds to messages and understands your
                    requirements. Thank you NT!!"{" "}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Urgent: React Native Developer</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    NT has a Good knowledge about Technologies. highly
                    Recommended to other people.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/user.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>WordPress, React.js, Java - Spring, Hibernate Jobs</h3>
                  <h4>Client</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>I
                    will hire him again for anykind of WordPress, Java, react.js
                    jobs. He was always available to answer my query. Good
                    communication, perfect job, on time delivery. Highly
                    recommended
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
