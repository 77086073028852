import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb({ pageList }) {
  return (
    <section class="breadcrumbs">
      <div class="container">
        <ol>
          {/* <li>
            <Link to="/">Home</Link>
          </li>
          <li>{pageName}</li> */}
          {pageList.map(function (el, i) {
            if (el.link)
              return (
                <li key={el.name}>
                  <Link to={el.link}>{el.name}</Link>
                </li>
              );
            return <li key={el.name}>{el.name}</li>;
          })}
        </ol>
        <h2>{pageList[pageList.length - 1].name}</h2>
      </div>
    </section>
  );
}

export default BreadCrumb;
