import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";

function Clients() {
  return (
    <section id="tabs" class="tabs">
      <div class="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Trending Technologies</h2>
        </div>
        <ul class="nav nav-tabs row d-flex row-cols-5">
          <li class="nav-item col">
            <Link
              class="nav-link active show"
              data-bs-toggle="tab"
              data-bs-target="#tab-1"
            >
              <i class="ri-smartphone-line d-lg-none"></i>
              <h5 class="d-none d-lg-block">Mobile</h5>
            </Link>
          </li>
          <li class="nav-item col">
            <Link class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
              <i class="ri-window-fill d-lg-none"></i>
              <h5 class="d-none d-lg-block">Front-End</h5>
            </Link>
          </li>
          <li class="nav-item col">
            <Link class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
              <i class="ri-links-line d-lg-none"></i>
              <h5 class="d-none d-lg-block">Back-End</h5>
            </Link>
          </li>
          <li class="nav-item col">
            <Link class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
              <i class="ri-database-2-line d-lg-none"></i>
              <h5 class="d-none d-lg-block">Database</h5>
            </Link>
          </li>
          <li class="nav-item col">
            <Link class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
              <i class="ri-computer-line d-lg-none"></i>
              <h5 class="d-none d-lg-block">CMS</h5>
            </Link>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane active show" id="tab-1">
            <div class="row">
              <div class="clients-slider swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={40}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 60,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 120,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper swiper-wrapper align-items-center"
                >
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/swift.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>SWIFT</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/android2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>ANDROID</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/flutter2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>FLUTTER</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/react2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>REACT NATIVE</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/xamarin.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>XAMARIN</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-2">
            <div class="row">
              <div class="clients-slider swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={40}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 60,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 120,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper swiper-wrapper align-items-center"
                >
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/react2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>REACT</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/angular2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>ANGULAR</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/vue2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>VUE</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/next.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>NEXT</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <div class="tab-pane" id="tab-3">
            <div class="row">
              <div class="clients-slider swiper">
                <Swiper
                  slidesPerView={5}
                  spaceBetween={40}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 60,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 120,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper swiper-wrapper align-items-center"
                >
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/node.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>NODE</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/java2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>JAVA</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/python2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>PYTHON</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/go.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>GO</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/rust.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>RUST</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/laravel2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>LARAVEL</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/dotnet2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>DOT NET</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/codeigniter2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>CODE IGNITER</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <div class="tab-pane" id="tab-4">
            <div class="row">
              <div class="clients-slider swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={40}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 60,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 120,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper swiper-wrapper align-items-center"
                >
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/mysql.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>MySql</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/mongodb.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>MongoDB</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/postgres.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>Postgres</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/redis.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>Redis</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <div class="tab-pane" id="tab-5">
            <div class="row">
              <div class="clients-slider swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={40}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 60,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 120,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper swiper-wrapper align-items-center"
                >
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/wordpress2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>WORDPRESS</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/magento.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>MAGENTO</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide class="swiper-slide">
                    <div className="swiper-slide-inner">
                      <img
                        src="./assets/img/tech/shopify2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div className="swiper-slide-inner-footer">
                        <h5>SHOPIFY</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
