import React from "react";
import { Link } from "react-router-dom";

function BlogList() {
  return (
    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4 posts-list">
          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Politics</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Dolorum optio tempore voluptas dignissimos
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Maria Doe</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Jan 1, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Sports</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Nisi magni odit consequatur autem nulla dolorem
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Allisa Mayer</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Jun 5, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Entertainment</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Possimus soluta ut id suscipit ea ut in quo quia et soluta
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Mark Dower</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Jun 22, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Sports</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Non rem rerum nam cum quo minus olor distincti
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Lisa Neymar</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Jun 30, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Politics</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Accusamus quaerat aliquam qui debitis facilis consequatur
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Denis Peterson</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Jan 30, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>
              <div className="post-img">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="post-category">Entertainment</p>

              <h2 className="title">
                <Link to="/blog-view">
                  Distinctio provident quibusdam numquam aperiam aut
                </Link>
              </h2>

              <div className="d-flex align-items-center">
                <img
                  src="assets/img/blog/blog-1.jpg"
                  alt=""
                  className="img-fluid post-author-img flex-shrink-0"
                />
                <div className="post-meta">
                  <p className="post-author-list">Mika Lendon</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Feb 14, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div className="blog-pagination">
          <ul className="justify-content-center">
            <li>
              <a href="/blog">1</a>
            </li>
            <li className="active">
              <a href="/blog">2</a>
            </li>
            <li>
              <a href="/blog">3</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default BlogList;
