import React from "react";

function Address() {
  return (
    <div id="address" className="team section">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Find Us Here</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img
                  src="assets/img/tajmahal.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h3>INDIA</h3>
                <div
                  className="d-flex footer-address"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/search/6th+Floor,+Block+D,+Silver+Oak+University+Campus,+SG+Highway,+Gota,+Ahmedabad-382481/@23.0753763,72.5392587,13z/data=!3m1!4b1"
                    )
                  }
                >
                  <i class="ri-map-pin-line"></i>
                  <p>
                    6th Floor, Block D, Silver Oak University Campus, SG
                    Highway, Gota, Ahmedabad-382481 <br />
                  </p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() => window.open("tel:+917495902142")}
                >
                  <i class="ri-smartphone-line"></i>
                  <p>
                    +91 74959 02142 <br />
                  </p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() =>
                    window.open(
                      "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@nexttechnolabs.com"
                    )
                  }
                >
                  <i class="ri-mail-line"></i>
                  <p>
                    sales@nexttechnolabs.com <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img
                  src="assets/img/newzealand.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h3>New Zealand</h3>
                <div
                  className="d-flex footer-address"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/place/29+Shankill+Place,+East+T%C4%81maki,+Auckland+2013,+New+Zealand/@-36.9433164,174.9038368,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0d4c8f0b9f1ca9:0xbdc89eefbae730f5!8m2!3d-36.9433207!4d174.9060255!16s%2Fg%2F11gf9f9r3v"
                    )
                  }
                >
                  <i class="ri-map-pin-line"></i>
                  <p>
                    29, Shankill place, East Tamaki Auckland 2013, New Zealand{" "}
                  </p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() => window.open("tel:+64225005524")}
                >
                  <i class="ri-smartphone-line"></i>
                  <p>
                    +64 22 500 5524 <br />
                  </p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() =>
                    window.open(
                      "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@nexttechnolabs.com"
                    )
                  }
                >
                  <i class="ri-mail-line"></i>
                  <p>
                    sales@nexttechnolabs.com <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img
                  src="assets/img/cntower.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h3>Canada</h3>
                <div
                  className="d-flex footer-address"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/place/2720+29+Ave+%239,+Regina,+SK+S4S+2N8,+Canada/@50.4125252,-104.6228205,17z/data=!3m1!4b1!4m5!3m4!1s0x531ea0b1ce61896d:0x6e2cea31216b3c67!8m2!3d50.4125218!4d-104.6206318"
                    )
                  }
                >
                  <i class="ri-map-pin-line"></i>
                  <p>9,2720, 29th Avenue, S4S2N8, Regina, SK, Canada</p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() => window.open("tel:+16399999798")}
                >
                  <i class="ri-smartphone-line"></i>
                  <p>
                    +1 (639) 999-9798 <br />
                  </p>
                </div>
                <div
                  className="d-flex items-center footer-call"
                  onClick={() =>
                    window.open(
                      "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@nexttechnolabs.com"
                    )
                  }
                >
                  <i class="ri-mail-line"></i>
                  <p>
                    sales@nexttechnolabs.com <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
