import React from "react";
// import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

function Hero() {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container" data-aos="zoom-out" data-aos-delay="100">
        <div className="row">
          <div className="col-xl-7">
            {/* <h1>Next Technolabs</h1> */}
            <h1>
              A Perfect Blend Of Result Driven Approach With Comprehensive
              Solution!
            </h1>
            {/* <Link to="#about" className="btn-get-started scrollto">
              Get Started
            </Link> */}
            <div className="desc">
              <ul>
                <li>
                  <i className="ri-check-double-line"></i> Harness The Power Of
                  IT Services, Enhance The User Experience
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Develop The Website &
                  Gear Up Your Business With Next Technolabs
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Attention-Driven Web
                  Designing? Stay Tuned
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 clients-slider swiper d-none d-lg-block ">
            <Swiper
              slidesPerView={1}
              spaceBetween={40}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 0,
                  spaceBetween: 40,
                },
                480: {
                  slidesPerView: 0,
                  spaceBetween: 60,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 80,
                },
                992: {
                  slidesPerView: 1,
                  spaceBetween: 120,
                },
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper swiper-wrapper align-items-center"
            >
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg1.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg2.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg3.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg4.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg5.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide">
                <img
                  src="./assets/img/banner/sliderimg6.png"
                  class="img-fluid"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
