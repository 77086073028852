import React from "react";
import About from "./About";
import Clients from "./Clients";
import Counts from "./Counts";
import Industries from "./Industries";
// import FAQ from "./FAQ";
import Services from "./Services";
import Tabs from "./Tabs";
// import Team from "./Team";
import Testimonials from "./Testimonials";

function Main() {
  return (
    <div id="main">
      <Clients />
      <About />
      {/* <Counts /> */}
      <Tabs />
      <Services />
      <Industries />
      <Testimonials />
      {/* <FAQ /> */}
      {/* <Team /> */}
      <Counts />
    </div>
  );
}

export default Main;
