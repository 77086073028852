import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

function Industries() {
  return (
    <section id="industries" class="clients section">
      <div className="container industries" data-aos="zoom-out">
        <div className="section-title">
          <h2>Industries We Serve</h2>
        </div>
        <div class="clients-slider swiper">
          <Swiper
            slidesPerView={5}
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 60,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 80,
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 120,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper swiper-wrapper align-items-center"
          >
            <SwiperSlide class="swiper-slide">
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/healthcare.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Health Care</h5>
                </div>
              </div>
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/automotive.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Automotive</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="swiper-slide">
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/bank.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Bank</h5>
                </div>
              </div>
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/deal.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Real Estate</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="swiper-slide">
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/dress.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Fashion</h5>
                </div>
              </div>
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/ecommerce.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Ecommerce</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="swiper-slide">
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/education.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Education</h5>
                </div>
              </div>
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/fitness.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Fitness</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="swiper-slide">
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/flight.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Tourism</h5>
                </div>
              </div>
              <div className="swiper-slide-inner">
                <img
                  src="./assets/img/industries/media.png"
                  class="img-fluid"
                  alt=""
                />
                <div className="swiper-slide-inner-footer">
                  <h5>Media</h5>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Industries;
