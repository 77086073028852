import React from "react";
// import { Link } from "react-router-dom";

function About() {
  return (
    <section id="about" class="about section-bg">
      <div class="container" data-aos="fade-up">
        <div class="content col d-flex align-items-stretch">
          <div class="content">
            <h3>Something Interesting About Us…</h3>
            {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut  labore et dolore magna aliqua. Duis
                aute irure dolor in reprehenderit
              </p>
              <Link to="#" class="about-btn">
                <span>About us</span> <i class="bx bx-chevron-right"></i>
              </Link> */}
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col d-flex align-items-stretch">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="bx bxs-bullseye"></i>
                  <h4>What Is Our Purpose?</h4>
                  <p>
                    The main goal of the Next Technolabs - Mobile app
                    development company in India is to provide the top-notch IT
                    services along with the time to time deliver, gives client
                    satisfaction.
                  </p>
                  <p>
                    To develop a reasonable, creative and attractive web
                    solution that can be suitable for small to large businesses
                    is the purpose of our team.
                    {/* Establish online visibility by
                    enhancing your area of services, make the website as per
                    your need is mandatory for us. */}
                  </p>
                </div>
                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="bx bxs-briefcase-alt"></i>
                  <h4>What We Do?</h4>
                  <p>
                    Next Technolabs has an array of services- from web design to
                    emerging technologies, we provide a wide range of services
                    to help your business, make it succeed in the competitive
                    online arena.
                  </p>
                  <p>
                    With the unbeatable web design and development strategies
                    and solutions, our team of experts have successfully
                    completed a number of websites, web solutions and mobile app
                    development in India as well.
                  </p>
                </div>
                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="bx bxs-select-multiple"></i>
                  <h4>Why Choose Us?</h4>
                  <p>
                    Our user-friendly and engaging web designs are effortless
                    and striking to behold. Not for the web design but we are
                    great to convert website visitors to customers, we believe
                    for resulting in business growth and success.
                  </p>
                </div>
                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="bx bx-shield"></i>
                  <h4>The Support We Provide</h4>
                  <p>
                    We provide a 24*7 support whether it is a maintenance, new
                    update, version update or any other need you can contact us.
                    We have helped hundreds of several businesses with custom
                    web solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
