import React, { useEffect, useRef, useState } from "react";

function Counts() {
  const componentRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  function animate(obj, initVal, lastVal, duration) {
    let startTime = null;

    // eslint-disable-next-line no-unused-vars
    let currentTime = Date.now();

    const step = (currentTime) => {
      if (!startTime) {
        startTime = currentTime;
      }

      const progress = Math.min((currentTime - startTime) / duration, 1);

      obj.innerHTML = `${Math.floor(
        progress * (lastVal - initVal) + initVal
      )}+`;

      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        window.cancelAnimationFrame(window.requestAnimationFrame(step));
      }
    };

    //start animating
    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });

    observer.observe(componentRef.current);

    let clientsCount = document.getElementById("clientsCount");
    let projectsCount = document.getElementById("projectsCount");
    let hoursCount = document.getElementById("hoursCount");
    let workersCount = document.getElementById("workersCount");

    animate(clientsCount, 0, 50, 2000);
    animate(projectsCount, 0, 100, 2000);
    animate(hoursCount, 0, 500, 2000);
    animate(workersCount, 0, 10, 2000);
  }, [myElementIsVisible]);

  return (
    <section id="counts" className="counts" ref={componentRef}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Support We Provide</h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="count-box">
              <i className="bi bi-emoji-smile"></i>
              <span
                id="clientsCount"
                data-purecounter-start="0"
                data-purecounter-end="232"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {/* {clientsCount} */}
              </span>
              <p>Happy Clients</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div className="count-box">
              <i className="bi bi-journal-richtext"></i>
              <span
                id="projectsCount"
                data-purecounter-start="0"
                data-purecounter-end="521"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {/* {projectsCount} */}
              </span>
              <p>Projects</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-headset"></i>
              <span
                id="hoursCount"
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {/* {hoursCount} */}
              </span>
              <p>Hours Of Support</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-people"></i>
              <span
                id="workersCount"
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {/* {workersCount} */}
              </span>
              <p>Hard Workers</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counts;
