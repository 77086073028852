import React, { useState } from "react";
import styled from "styled-components";

function ScrollToTop() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Button
      onClick={scrollTop}
      style={{ display: visible ? "inline" : "none" }}
    >
      <i
        class="bi bi-arrow-up-short"
        style={{
          fontSize: "28px",
          color: "#fff",
        }}
      ></i>
    </Button>
  );
}

export default ScrollToTop;

const Button = styled.div`
  position: fixed;
  z-index: 996;
  cursor: pointer;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #e03a3c;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  text-align: center;

  :hover {
    background: #e65d5f;
    color: #fff;
  }
`;
