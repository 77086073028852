import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function TawkChat() {
  const tawkPropertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
  const tawkWidgetId = process.env.REACT_APP_TAWK_WIDGET_ID;

  // console.log("prop id", tawkPropertyId);
  // console.log("wid id", tawkWidgetId);
  return (
    <TawkMessengerReact
      className="tawkChatWidget"
      propertyId={tawkPropertyId}
      widgetId={tawkWidgetId}
    />
  );
}

export default TawkChat;
