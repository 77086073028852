import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    // <footer id="footer">
    //   <div className="container d-md-flex py-4">
    //     <div className="row">
    //       <div className="col-lg-6 col-md-6 footer-contact">
    //         <h3>
    //           Next <span>Technolabs</span>
    //         </h3>
    //       </div>

    //       <div className="col-lg-3 col-md-6 footer-links">
    //         <h4>Useful Links</h4>
    //         <ul>
    //           <li>
    //             <i className="bx bx-chevron-right"></i> <Link to="/">Home</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#about">About us</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Services</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="#">Terms of service</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="#">Privacy policy</Link>
    //           </li>
    //         </ul>
    //       </div>

    //       <div className="col-lg-3 col-md-6 footer-links">
    //         <h4>Our Services</h4>
    //         <ul>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Web Design</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Web Development</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Product Management</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Marketing</Link>
    //           </li>
    //           <li>
    //             <i className="bx bx-chevron-right"></i>{" "}
    //             <Link to="/#services">Graphic Design</Link>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className="me-md-auto text-center text-md-start">
    //       <div className="copyright">
    //         &copy; Copyright{" "}
    //         <strong>
    //           <span>Next Technolabs</span>
    //         </strong>
    //         . All Rights Reserved
    //       </div>
    //     </div>
    //     <div className="social-links text-center text-md-end pt-3 pt-md-0">
    //       <a
    //         href="https://twitter.com/home"
    //         target="_blank"
    //         rel="noreferrer"
    //         className="twitter"
    //       >
    //         <i className="bx bxl-twitter"></i>
    //       </a>
    //       <a
    //         href="https://www.facebook.com/"
    //         target="_blank"
    //         rel="noreferrer"
    //         className="facebook"
    //       >
    //         <i className="bx bxl-facebook"></i>
    //       </a>
    //       <a
    //         href="https://www.instagram.com/nexttechnolabs/?hl=en"
    //         target="_blank"
    //         rel="noreferrer"
    //         className="instagram"
    //       >
    //         <i className="bx bxl-instagram"></i>
    //       </a>
    //       <Link to="#" className="google-plus">
    //         <i className="bx bxl-skype"></i>
    //       </Link>
    //       <a
    //         href="https://in.linkedin.com/company/next-technolabs?original_referer=https%3A%2F%2Fwww.google.com%2F"
    //         target="_blank"
    //         rel="noreferrer"
    //         className="linkedin"
    //       >
    //         <i className="bx bxl-linkedin"></i>
    //       </a>
    //     </div>
    //   </div>
    // </footer>
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 footer-contact">
              <h3>
                Next<span> Technolabs</span>
              </h3>
              <p>
                Highly dedicated to providing innovative solutions to your
                business with a team of experienced professionals, we leverage
                the latest technologies to deliver efficient and effective
                results.
              </p>
              <p>
                Our goal is to help our clients streamline processes, improve
                productivity, and drive success.
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i> <Link to="/">Home</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#about">About us</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">Services</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#contact">Contact</Link>
                </li>
                {/* <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#">Privacy policy</a>
                </li> */}
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">Web Design</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">Web Development</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">App Development</Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">Product Management</Link>
                </li>
                {/* <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/#services">Marketing</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Next Technolabs</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
        <div class="social-links text-center text-md-end pt-3 pt-md-0">
          <a
            href="https://twitter.com/home"
            target="_blank"
            rel="noreferrer"
            className="twitter"
          >
            <i class="bx bxl-twitter"></i>
          </a>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noreferrer"
            className="facebook"
          >
            <i class="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/nexttechnolabs/?hl=en"
            target="_blank"
            rel="noreferrer"
            className="instagram"
          >
            <i class="bx bxl-instagram"></i>
          </a>
          {/* <a href="#" class="google-plus">
            <i class="bx bxl-skype"></i>
          </a> */}
          <a
            href="https://in.linkedin.com/company/next-technolabs?original_referer=https%3A%2F%2Fwww.google.com%2F"
            target="_blank"
            rel="noreferrer"
            className="linkedin"
          >
            <i class="bx bxl-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
