import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);

  const EMAILJS_SERVICE_ID = 'service_0bmzs3d';
  const EMAILJS_TEMPLATE_ID = 'template_0yr3egn';
  const EMAILJS_PUBLIC = 'bf5f2isdojQbhT0HM';

  const submitForm = async (event) => {
    event.preventDefault();
    const rqd = {
      name,
      email,
      subject,
      message,
    };
    try {
      // console.log(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_PUBLIC, rqd);
      await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        rqd,
        EMAILJS_PUBLIC
      );

      toastifySuccess({
        note: "Form sent successfully!",
      });
      resetForm();
      setIsSent(true);
    } catch (e) {
      toastifySuccess({
        note: "UH OH! form not sent!!",
      });
      console.log(e);
    }
  };

  const toastifySuccess = ({ note }) => {
    toast(note, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  // const formSubmit = () => {
  //   console.log("form submitted");
  // };

  return (
    <section id="contact" className="contact section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact Us</h2>
          {/* <p>We are here for you.</p> */}
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="row">
              <div
                className="col-md-12 cursorPoint"
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/search/6th+Floor,+Block+D,+Silver+Oak+University+Campus,+SG+Highway,+Gota,+Ahmedabad-382481/@23.0753763,72.5392587,13z/data=!3m1!4b1"
                  )
                }
              >
                <div className="info-box">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  6th Floor, Block D, Silver Oak University Campus, <br />
                  Opp. Bhagwat Vidhyapith, SG Highway, Gota <br />
                  Ahmedabad 382481 <br />
                </div>
              </div>
              <div
                className="col-md-6 cursorPoint"
                onClick={() =>
                  window.open(
                    "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@nexttechnolabs.com"
                  )
                }
              >
                <div className="info-box mt-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    sales@nexttechnolabs.com
                    <br />
                    {/* contact@example.com */}
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 cursorPoint"
                onClick={() => window.open("tel:+917495902142")}
              >
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>
                    +91 7495902142
                    <br />
                    {/* +91 7495 902 142 */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <form onSubmit={submitForm} className="php-email-form">
              <div className="row">
                <div className="col form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    required
                  />
                </div>
                <div className="col form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                  value={subject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  rows="7"
                  placeholder="Message"
                  data-rule="required"
                  data-msg="Please write something for us"
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                {isSent && (
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                )}
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
