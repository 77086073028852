import React from "react";
import {
  Contact,
  Footer,
  Hero,
  NavBar,
  Main,
  // ScrollToTop,
} from "../components";
import Address from "../components/Address";

function Home() {
  return (
    <div>
      <NavBar />
      <Hero />
      <Main />
      <Contact />
      <Address />
      <Footer />
      {/* <ScrollToTop /> */}
    </div>
  );
}

export default Home;
